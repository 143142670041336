import ResponseModel from "./response-model";

async function fetchEventoParametros({sindicatoUrl,eventoUrl}) 
{
  const url = new URL(process.env.REACT_APP_URL_API_ASSEMBLEIA + 
    '/eventos/parametro/' + sindicatoUrl+'/'+eventoUrl);

    const fet = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'   
        }
    });
      switch (fet.status) {
        case 200:
          return {success:true, status:fet.status,data:await fet.json()};
        case 404:          
          return {success:false, status:fet.status,data:null};
        default:
          alert('Ops... tente novamente mais tarde');
          console.error(await fet.text());
          break;
      }
      return null; 
  
}

async function fetchAtendimentoAtivo({eventoId}) 
{
  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + 
    '/eventos/' + eventoId+'/atendimento-ativo');

    const fet = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'   
        }
    });


    const response = new ResponseModel();    
    return await response.init(fet); 
  
}

async function fetchTipoBusca({eventoId}) 
{
  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + 
    '/eventos/' + eventoId+'/tipo-busca');

    const fet = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'   
        }
    });


    const response = new ResponseModel();    
    return await response.init(fet); 
  
}

async function fetchTempoEsperadoAtendimento({eventoId}) 
{
  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + 
    '/eventos/' + eventoId+'/tempo-esperado-atendimento');

    const fet = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'   
        }
    });

    const response = new ResponseModel();    
    return await response.init(fet);  
}
const evento = {
  fetchEventoParametros,
  fetchTempoEsperadoAtendimento,
  fetchAtendimentoAtivo,
  fetchTipoBusca
};

export default evento;