import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import atendimentoMesa from '../../api/atendimentoMesa';
import evento from '../../api/evento';
import AtendimentoRedirect from './AtendimentoRedirect';
import FormFindVotante from './FormFindVotante';
import FormVotante from './FormVotante';


function getQueryParams() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('sindicatoUrl');
    searchParams.delete('eventoUrl');
    let params = {};
    searchParams.forEach(function (val, key) {
        params[key] = val;
    });
    return params;

}


class Receber extends React.Component {


    state = {
        tela: 'busca',
        atendimentoToken: null,
        msgErro: null,
        msgErroAtendimentoPost: '',
        evento: {},
        loading: true,
        atendimentoAtivo: false,
        sindicatoUrl: null,
        eventoUrl: null,
        initQuery: {}
    }

    componentDidMount() {
        this.fetchEvento(this.getQueryEvento());
        this.setState({initQuery: getQueryParams()});
    }

    achouVotanteHandle = async ({ votanteUuid }) => {
        if (votanteUuid !== null) {
            this.setState({loading:true});
            const returnAtendimento = await this.atendimentoPost({ votanteUuid });
            if (!returnAtendimento.success) {
                this.setState({loading:false});
                this.setState({
                        msgErroAtendimentoPost:returnAtendimento.data.mensagem,
                        initQuery: {}});
                return
            }
            this.mesaRedirect(returnAtendimento.data);
            this.setState({loading:false});
        } else {
            this.setState({ tela: 'inclui' });
        }
    }

    atendimentoPost = async ({ votanteUuid, votanteRascunho }) => {
        return atendimentoMesa.post(
            {
                eventoId:this.state.evento.id,
                votanteUuid,
                votanteRascunho
            }
        );

    }

    mesaRedirect = ({atendimentoToken}) => {
        this.setState({ tela: 'redireciona',
                        atendimentoToken });
    }

    votanteSubmitHandle = (votanteRascunho) => {
        this.incluirAtendimento({ votanteRascunho });
    }

    getQueryEvento = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const sindicatoUrl = searchParams.get('sindicatoUrl');
        const eventoUrl = searchParams.get('eventoUrl');
        return {
            sindicatoUrl,
            eventoUrl
        };
    }

    fetchEvento = async ({ sindicatoUrl, eventoUrl }) => {
        this.setState({loading:true});
        this.setState({sindicatoUrl,eventoUrl});
        const eventoReturn = await evento.fetchEventoParametros({ sindicatoUrl, eventoUrl });
        if (!eventoReturn.success) {
            this.setState({ msgErro: 'Evento não encontrado' });
        } else {
            const atendimentoAtivoReturn = await evento.fetchAtendimentoAtivo({eventoId:eventoReturn.data.id});
            this.setState({atendimentoAtivo:atendimentoAtivoReturn.getData().ativo});
        }
        this.setState({loading:false});
        this.setState({ evento: eventoReturn.data });
    }

    reiniciar = () => {
        this.setState({tela:"busca"});
    } 


    render() {
        if (this.state.msgErro) {
            return <Alert variant="danger">{this.state.msgErro}</Alert>
        }
       if (this.state.loading) {
            return (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Carregando...</span>
                    </Spinner>
                </div>
                );
        }
        
        
        if (!this.state.atendimentoAtivo) {
            return (
            <Alert variant="danger" className="text-center">
                <h2 className="font-weight-bold">
                    Votação Fechada
                </h2>
            </Alert>);
        }


        switch (this.state.tela) {
            case 'busca':
                return (
                    <> 
                        {this.state.msgErroAtendimentoPost &&
                        <Alert variant="danger">{this.state.msgErroAtendimentoPost}</Alert>
                        }                   
                        <FormFindVotante evento={this.state.evento} initFindParams={this.state.initQuery} onAchouVotante={this.achouVotanteHandle} />
                    </>
                );
            case 'inclui':
                return (
                    <FormVotante 
                        evento={this.state.evento}
                        onParticipanteInserted={this.mesaRedirect}
                        atendimentoPost={this.atendimentoPost} />
                );
            case 'redireciona':
                return (
                    <AtendimentoRedirect 
                        atendimentoToken={this.state.atendimentoToken} 
                        sindicatoUrl={this.state.sindicatoUrl}
                        eventoUrl={this.state.eventoUrl}
                        onReiniciarAtendimento={this.reiniciar}
                    />
                );
            default:
                return null;
        } 

    }
}

export default Receber;