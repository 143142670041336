import React from 'react';
import PropTypes from 'prop-types';

function TextoAguarde(props) {
    return (
        <div className={props.className}>
            <h1 className="font-weight-bold">Aguarde seu atendimento</h1>
            <p>Teste seu microfone e câmera enquanto aguarda na fila virtual. Aproveite para providenciar um documento com foto para apresentar ao mesário.</p>
            <p><small className="font-weight-bold text-danger">Ao entrar na sala de atendimento você será filmado para fins de confirmação da sua identidade.</small></p>
        </div>
    );

}

TextoAguarde.propTypes = {
    className: PropTypes.object
}
export default TextoAguarde;