import React, { useRef } from 'react';
import VideoRoom from '@panagora/antmedia-conference-reactjs';
import '@panagora/antmedia-conference-reactjs/dist/index.css';
import PropTypes from 'prop-types';
import moment from 'moment';
const { REACT_APP_URL_API_VIDEOCONFERENCIA } = process.env;

function SalaDeAtendimento(props) {

    const messageManager = useRef(null);
    const erroEnviado = useRef(null);
    const msgRecebida = useRef(false);
    const lastSend = useRef(null);
    

    function leaveHandler() {
        if (confirm('Têm certeza que gostaria de sair da sala?')) {
            window.close();
        }
    }

    function onError(erro) {
        if (!messageManager.current) {
            return;
        }
      
        if ((msgRecebida.current) && (erroEnviado.current === erro)) {
            return;
        }
        if (moment(lastSend.current) >= moment().subtract(10, 's')) {
            return;
        }
        const nickname = 'Votante';        
        switch (erro) {
            case 'NotAllowedError':
                messageManager.current.sendMessage({nickname,message: '----Câmera/Microfone não autorizados----'})
                break;
            case 'NotReadableError':
                messageManager.current.sendMessage({nickname,message: '----Câmera/Microfone ocupados por outro sistema----'})
                break;
            case 'streamIdInUse':
                messageManager.current.sendMessage({nickname,message: '----Duas abas do sistema abertas----'})
                break;
            default:
                messageManager.current.sendMessage({nickname,message: '----Erro desconhecido "'+erro+'"----'})
                break;
        } 
        lastSend.current = new Date();
        erroEnviado.current = erro;
    }

    function onReceiveMessage({type}) {
        if (type === 'SEND_MSG') {
            msgRecebida.current = true;
        }
    }

    return (
        <VideoRoom
            token={props.token}
            leaveHandler={leaveHandler}
            urlPublisher={props.urlPublish}
            urlPlayer={props.urlPlay}
            urlApi={REACT_APP_URL_API_VIDEOCONFERENCIA}
            setMessageManager={(mm) => { messageManager.current = mm }}
            onError={onError}
            onReceiveMessage={onReceiveMessage}
            checkConnection={false}
        />
    )

}


SalaDeAtendimento.propTypes = {
    token: PropTypes.string.isRequired,
    urlPublish: PropTypes.string.isRequired,
    urlPlay: PropTypes.string.isRequired
}

export default SalaDeAtendimento;