import React, { useEffect, useState } from 'react';
import SalaDeAtendimento from './SalaDeAtendimento';
import {
    useParams
} from "react-router-dom";
import SalaDeEspera from './sala-de-espera';
import atendimentoMesa from '../../api/atendimentoMesa';
import eventoApi from '../../api/evento';
import Alert from 'react-bootstrap/Alert';
import PosAtendimento from './pos-atendimento';
import AtendimentoDescartado from './AtendimentoDescartado';
import SinalOnline from '../../components/sinal-online';

const STATUS_FILA = 'fila';
const STATUS_ATENDIDO = 'atendido';
const STATUS_ATENDENDO = 'atendendo';
const STATUS_DESCARTADO = 'descartado';
const STATUS_DESCARTADO_AUTOMATICAMENTE = 'descartado-automaticamente';


function SalaAtendimento() {
    const { atendimentoToken, sindicatoUrl, eventoUrl } = useParams();

    const [posicaoAtendimento, setPosicaoAtendimento] = useState({
        posicao: null,
        segundos: null
    });
    const [videoconferencia, setVideoconferencia] = useState(null);
    const [msgErro, setMsgErro] = useState(null);
    const [evento, setEvento] = useState(null);
    const [statusAtendimentoMesaId, setStatusAtendimentoMesaId] = useState(STATUS_FILA);


    useEffect(() => {
        async function fetchEvento() {
            const eventoReturn = await eventoApi.fetchEventoParametros({ sindicatoUrl, eventoUrl });
            if (!eventoReturn.success) {
                setMsgErro('Evento não encontrado');
                return;
            }
            setEvento(eventoReturn.data);
        }
        fetchEvento();
    }, [eventoUrl, sindicatoUrl]);


    useEffect(() => {
        async function fetchPosicaoAtendimento() {
            const res = await atendimentoMesa.fetchPosicaoAtendimento({ atendimentoToken });
            if (!res.isSuccess()) {
                if (res.getStatus() === 404) {
                    setMsgErro('Atendimento não encontrado');
                    clearInterval(timer);
                }
                return;
            }
            const { statusAtendimentoMesaId } = res.getData();
            switch (statusAtendimentoMesaId) {
                case STATUS_FILA:
                    setPosicaoAtendimento({
                        posicao: (res.getData().fila.posicao ? res.getData().fila.posicao : null),
                        segundos: (res.getData().fila.segundosEstimados ? res.getData().fila.segundosEstimados : null)
                    });
                    break;
                case STATUS_ATENDENDO:
                    setVideoconferencia(res.getData().atendendo);
                    break;
                case STATUS_ATENDIDO:
                case STATUS_DESCARTADO:
                    clearInterval(timer);
                    break;
            }
            setStatusAtendimentoMesaId(statusAtendimentoMesaId);
        }
        const timer = setInterval(() => {
            fetchPosicaoAtendimento();
        }, 5000);

        fetchPosicaoAtendimento();
        return () => {
            clearInterval(timer);
        }
    }, [atendimentoToken]);


    if (msgErro) {
        return <Alert variant="danger">{msgErro}</Alert>
    }

    switch (statusAtendimentoMesaId) {
        case STATUS_FILA:
            return (
                <>
                    <SinalOnline atendimentoToken={atendimentoToken} />
                    <SalaDeEspera
                        evento={evento}
                        posicao={posicaoAtendimento.posicao}
                        segundosEstimados={posicaoAtendimento.segundos} />
                </>);

        case STATUS_ATENDENDO:
            return (
                <>
                    <SinalOnline atendimentoToken={atendimentoToken} />
                    <SalaDeAtendimento token={videoconferencia.token} urlPublish={videoconferencia.urlPublish} urlPlay={videoconferencia.urlPlay} />
                </>
            );
        case STATUS_ATENDIDO:
            return (<PosAtendimento evento={evento} atendimentoToken={atendimentoToken} />)
        case STATUS_DESCARTADO:
        case STATUS_DESCARTADO_AUTOMATICAMENTE:            
            return (<AtendimentoDescartado evento={evento} />)

    }


}


export default SalaAtendimento;