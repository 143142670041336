
import React from 'react';

function MsgNaoAprovado() {


    return (
        <>
            <h1 className="font-weight-bold">O seu atendimento foi finalizado</h1>
            <p>Caso você tenha sido identificado e aprovado pelos mesários, irá receber por SMS ou e-mail o link de votação.</p>
        </>
    )

}


export default MsgNaoAprovado;