import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Styles from './index.module.css';
import TextoAguarde from './TextoAguarde';
import Posicao from './Posicao';
import TempoEstimado from './TempoEstimado';
import CamMicTest from '@panagora/cam-mic-test-screen'
import '@panagora/cam-mic-test-screen/dist/index.css'
import Cabecalho from '../../../components/Cabecalho';


function SalaDeEspera(props) {
    return (
        <>
            <Cabecalho evento={props.evento} />
            <Container fluid className={Styles.container}>
                <Row className="h-100">
                    <Col md={6} className="my-auto">
                        <Col md={10} lg={8} xl={7} className="mx-auto">
                            <CamMicTest className="my-5"/>
                        </Col>
                    </Col>
                    <Col md={6} className="text-center my-auto">
                        <TextoAguarde/>
                        <Posicao posicao={props.posicao} />
                        <TempoEstimado className="mt-4" segundosEstimados={props.segundosEstimados}/>
                    </Col>

                </Row>
            </Container>
        </>
    );

}

SalaDeEspera.propTypes = {
    evento: PropTypes.object,
    posicao: PropTypes.number,
    segundosEstimados: PropTypes.number
}

export default SalaDeEspera;