import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import PropTypes from 'prop-types';
import atendimentoMesa from '../../../api/atendimentoMesa';


function AvaliacaoAtendimento(props) {

   const [avaliacao,setAvaliacao] = useState(0);
   

    function ratingChanged(newRating) {
        atendimentoMesa.avaliarPost({
            atendimentoToken: props.atendimentoToken,
            avaliacao: newRating
        });
        setAvaliacao(newRating);
    }


    return (
        <div className={props.className}>
            <h5 className="mb-0 text-muted">Avalie o Atendimento</h5>
            <ReactStars
                key={avaliacao}
                value={avaliacao}
                edit={avaliacao === 0}
                classNames="mx-auto"
                count={5}
                onChange={ratingChanged}
                size={64}
                activeColor="#ffd700"
            />
        </div>
    );
}

AvaliacaoAtendimento.propTypes = {
    className: PropTypes.string,
    atendimentoToken: PropTypes.string.isRequired
};


export default AvaliacaoAtendimento;