import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import ReactInputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';
import PropTypes from 'prop-types';


class Cpf extends React.Component {

    static propTypes = {
        cpf: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        invalid: false,
        valid: false
    }


    handleChangeCpf = (event) => {
        const cpfDig = event.target.value;

        if (cpf.strip(cpfDig).length !== 11) {
            this.setState({
                invalid: false,
                valid: false
            });
            this.props.onChange({ data:{cpf: cpfDig},valid:false })
            return;
        }
        const valido = cpf.isValid(cpfDig); 
        this.props.onChange({ data:{cpf: cpfDig},valid: valido })
        this.setState({
            invalid: !valido,
            valid: valido
        });
    }


    render() {
        return (
            <FormGroup controlId="formCpf">
                <FormLabel>CPF</FormLabel>
                <ReactInputMask mask="999.999.999-99" value={this.props.cpf} onChange={this.handleChangeCpf}>
                    <FormControl autoComplete="off"
                        isInvalid={this.state.invalid}
                        isValid={this.state.valid}
                        size="lg" name="cpf" required placeholder="Digite seu CPF" />
                </ReactInputMask>
            </FormGroup>
        );
    }
}

export default Cpf;