import React from 'react';
import atendimentoMesa from '../../api/atendimentoMesa';
import PropTypes from 'prop-types';



export default class SinalOnline extends React.Component {


    interval = null;


    componentDidMount = () => {
        this.iniciaTimer();
        this.enviaSinal();
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    iniciaTimer = () => {
        this.interval = setInterval(() => {
            this.enviaSinal();
        }, 30000);
    }

    enviaSinal = async () => {
        const returnSinal = await atendimentoMesa.sinalOnline({
            atendimentoToken: this.props.atendimentoToken
        });

        if (!returnSinal.isSuccess()) {
            alert(returnSinal.getData().mensagem);
        }
    }

    render() {
        return null;
    }
}


SinalOnline.propTypes = {
    atendimentoToken: PropTypes.string.isRequired
}
