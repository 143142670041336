import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';


function AtendimentoRedirect(props) {

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const urlObj = new URL(props.sindicatoUrl + '/' + props.eventoUrl +
      '/sala-atendimento/' + props.atendimentoToken,
      window.location.href);

    setUrl(urlObj.href);
    window.open(urlObj.href);
  }, [props.atendimentoToken, props.sindicatoUrl, props.eventoUrl]);

  function reiniciarAtendimento(e) {
    e.preventDefault();
    props.onReiniciarAtendimento();
  }

  function redirect() {
    window.open(url);
  }

  return (
    <div className="text-center">
      <p>
        Estamos tentando te direcionar para a sala de atendimento,
        caso isso não ocorra em alguns segundos, clique no botão a baixo
      </p>
      <p>
        <Button size="lg" onClick={redirect}>Entrar na Sala de Atendimento</Button><br/>
        ou <a href="#" onClick={reiniciarAtendimento}>clique aqui</a> para reiniciar o atendimento
      </p>
    </div>
  );

}


AtendimentoRedirect.propTypes = {
  atendimentoToken: PropTypes.string.isRequired,
  sindicatoUrl: PropTypes.string.isRequired,
  eventoUrl: PropTypes.string.isRequired,
  onReiniciarAtendimento: PropTypes.func.isRequired
};

export default AtendimentoRedirect;