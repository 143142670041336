import React from 'react';
import PropTypes from 'prop-types';
import Cabecalho from '../../components/Cabecalho';
import Container from 'react-bootstrap/Container';
import Styles from './atendimento-descartado.module.css';
import Button from 'react-bootstrap/Button';


function AtendimentoDescartado(props) {

    function fecharClick() {
        window.close();
    }
    
    return (
        <>
            <Cabecalho evento={props.evento} />
            <Container className={Styles.container} fluid>
                <h1 className="font-weight-bold">Ops... Seu atendimento não foi realizado</h1>
                <p>Por algum motivo, o mesário não conseguiu realizar seu atendimento, por favor entre novamente na fila.</p>

                <Button onClick={fecharClick} variant="primary" size="lg">Fechar</Button>

            </Container>
        </>
    )

}


AtendimentoDescartado.propTypes = {
    evento: PropTypes.object.isRequired
}

export default AtendimentoDescartado;