import React from 'react';
import evento from '../../api/evento';
import PropTypes from 'prop-types';
const { REACT_APP_MARGEM_TEMPO_ESPERA } = process.env;

class TempoDeAtendimento extends React.Component {
  static propTypes = {
    evento: PropTypes.object
};


  state = {
    tempoSegundos: null
  };

  componentDidMount = async () => {
    this.fetchTempoEsperado(this.props.evento);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.evento !== this.props.evento) {
      this.fetchTempoEsperado(this.props.evento);
    }
  }

  fetchTempoEsperado = async (eventoObj) => {
    if (Object.keys(eventoObj).length === 0) {
      return;
    }
    const tempoReturn = await evento.fetchTempoEsperadoAtendimento({eventoId:eventoObj.id});

    if (tempoReturn.isSuccess()) {
      this.setState({tempoSegundos:tempoReturn.data.tempo});
    }
  }

  render() {
    if (this.state.tempoSegundos === null) {
      return null;
    }
    let tempoMin = this.state.tempoSegundos-parseInt(REACT_APP_MARGEM_TEMPO_ESPERA);
    if (tempoMin < 0) {
      tempoMin = 0;
    }
    const tempoMax = this.state.tempoSegundos+parseInt(REACT_APP_MARGEM_TEMPO_ESPERA);

    return (<span>de {Math.round(tempoMin/60)} à {Math.round(tempoMax/60)} minutos</span>);
  }
}

export default TempoDeAtendimento;
