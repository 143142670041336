import React from 'react';
import PropTypes from 'prop-types';
import Styles from './posicao.module.css';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Posicao(props) {
    return (
        <Row className="justify-content-center">
            <Col md={6} xs={10} className={classNames(Styles.container,"d-flex justify-content-center")}>
                <div>Sua posição na fila: </div>
                <div className="ml-2 px-2 text-white bg-dark">{props.posicao}</div>
            </Col>
        </Row>
    );

}

Posicao.propTypes = {
    posicao: PropTypes.number
}
export default Posicao;