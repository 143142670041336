import React from 'react';

function NotFound() {
  return (
        <p>
          Página não encontrada
        </p>
  );
}

export default NotFound;
