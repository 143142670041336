import React from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';


class Layout extends React.Component {
  render() {
    return (
      <Container fluid className="h-100">
        {this.props.children}
      </Container>
    );
  }
}


Layout.propTypes = {
  children: PropTypes.element
};

export default Layout;
