import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Cpf from './Cpf';
import TempoDeAtendimento from './TempoDeAtendimento';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import votante from '../../api/votante';
import eventoApi from '../../api/evento';
import MatriculaFuncional from './MatriculaFuncional';
import Spinner from 'react-bootstrap/Spinner';


function FieldFindVotante({ evento, onChange, value }) {
    const [tipoBusca, setTipoBusca] = useState(null);
    const [loading, setLoading] = useState(true);
    const eventoId = evento?.id;
    const tituloMatricula = evento?.tituloMatricula;


    useEffect(() => {
        if (!eventoId) {
            return;
        }
        async function fetchTipoBusca() {
            setLoading(true);
            const tipoBuscaFetch = await eventoApi.fetchTipoBusca({ eventoId });
            setLoading(false);
            if (tipoBuscaFetch.isSuccess()) {
                setTipoBusca(tipoBuscaFetch.getData()['tipoBusca']);
            }
        }

        fetchTipoBusca();
    }, [eventoId]);


    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            </div>
        );
    }
    switch (tipoBusca) {
        case 'cpf':
            return <Cpf cpf={value.cpf || ''} onChange={onChange} />
        case 'matricula-funcional':
            return <MatriculaFuncional
                value={value.matriculaFuncional || ''}
                onChange={onChange}
                titulo={tituloMatricula} />
        default:
            return null;
    }
}


FieldFindVotante.propTypes = {
    onChange: PropTypes.func.isRequired,
    evento: PropTypes.object,
    value: PropTypes.object.isRequired
}


function FormFindVotante({ onAchouVotante, initFindParams, evento }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({ data: {}, valid: false });

    const fetchVotante = useCallback(async (params) => {
        if (!evento.id) return;
        setLoading(true);
        const votanteReturn = await votante.fetchVotanteId(
            {
                data: params,
                eventoId: evento.id
            });
        setLoading(false);
        if (votanteReturn.getStatus() === 404) {
            onAchouVotante({ votanteUuid: null });
            return;
        } else if (votanteReturn.getStatus() === 200) {
            onAchouVotante({ votanteUuid: votanteReturn.getData().votanteUuid });
            return;
        }

        if (!votanteReturn.isSuccess()) {
            alert(votanteReturn.getData().mensagem);
        }
    }, [onAchouVotante, evento.id]);

    function onChange({ data, valid }) {
        setFields({ data, valid });
    }

    useEffect(() => {
        if (Object.keys(initFindParams).length > 0) {
            fetchVotante(initFindParams);
        }
    }, [fetchVotante, initFindParams]);


    async function handleSubmit(e) {
        e.preventDefault();

        if (!fields.valid) {
            return;
        }

        await fetchVotante(fields.data, evento.id);

    }

    if (Object.keys(initFindParams).length > 0) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div>
            <Row className="justify-content-sm-center">
                <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                    <Form noValidate id='formReceber'
                        onSubmit={handleSubmit}>
                        <FieldFindVotante onChange={onChange} evento={evento} value={fields.data} />
                        <div className="text-right">
                            <Button variant="primary" type="submit" disabled={loading} size="lg">Entrar</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Tempo esperado para atendimento: <b><TempoDeAtendimento evento={evento} /></b></small>
                </Col>
            </Row>
        </div>
    );
}

FormFindVotante.propTypes = {
    onAchouVotante: PropTypes.func,
    evento: PropTypes.shape({ id: PropTypes.number }),
    initFindParams: PropTypes.object
}

export { FieldFindVotante };
export default FormFindVotante;
