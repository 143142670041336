import React from 'react';
import ReactDOM from 'react-dom';
import Layout from './app/Layout';
import reportWebVitals from './reportWebVitals';
import './scss/custom.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './app/NotFound';
import Receber from './features/receber';
import SalaAtendimento from './features/sala-atendimento';


ReactDOM.render(
  <React.StrictMode>
    <Layout>
      <BrowserRouter>
        <Switch>
          <Route exact path="/:sindicatoUrl/:eventoUrl/sala-atendimento/:atendimentoToken" component={SalaAtendimento} />
          <Route exact path="/" component={Receber} />
          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </Layout>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
