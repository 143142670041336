import ResponseModel from "./response-model";

async function fetchList(idEvento) 
{

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/eventos/' + idEvento+'/urnas');


    const fet = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
    });


    const response = new ResponseModel();    
    return await response.init(fet);
  
}



const urna = {fetchList};

export default urna;