import ResponseModel from "./response-model";

async function fetchVotanteId({data,eventoId}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/eventos/' + eventoId + 
        '/votante-id');

  const fet = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });


  const response = new ResponseModel();    
  return await response.init(fet);

}

const votante = { fetchVotanteId };

export default votante;