import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import PropTypes from 'prop-types';


function Cabecalho(props) {
    return (
        <Navbar bg="light" fixed="top">
            <Navbar.Brand className="text-uppercase font-weight-bold">{props.evento && props.evento.nome}</Navbar.Brand>
        </Navbar>
    );

}

Cabecalho.propTypes = {
    evento: PropTypes.object
}

export default Cabecalho;