
import React, { useCallback, useEffect, useState } from 'react';
import Cabecalho from '../../../components/Cabecalho';
import PropTypes from 'prop-types';
import atendimentoMesa from '../../../api/atendimentoMesa';
import Spinner from 'react-bootstrap/Spinner';
import MsgAprovado from './MsgAprovado';
import MsgNaoAprovado from './MsgNaoAprovado';
import AvaliacaoAtendimento from './AvaliacaoAtendimento';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Styles from './index.module.css';

function PosAtendimento(props) {

    const [linkVotacao, setLinkVotacao] = useState(null);
    const [sucesso, setSucesso] = useState(null);



    const redirectVotacao = useCallback(() => {
        window.location.href = linkVotacao;
    }, [linkVotacao]);

    useEffect(() => {
        async function fetchLinkVotacao() {
            const ret = await atendimentoMesa.fetchLinkVotacao({ atendimentoToken: props.atendimentoToken });
            if (ret.isSuccess()) {
                setLinkVotacao(ret.getData().link);
            }
            setSucesso(ret.isSuccess());
        }
        fetchLinkVotacao();

    }, [props.atendimentoToken]);

    useEffect(() => {
        let timer = null;
        if (sucesso) {
            timer = setTimeout(() => {
                redirectVotacao();
            }, 30000);
        }

        return () => {
            clearTimeout(timer);
        }
    }, [sucesso, redirectVotacao]);


    function continuarClick() {
        if (sucesso) {
            redirectVotacao();
        } else {
           window.close();
        }
    }



    if (sucesso === null) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <>
            <Cabecalho evento={props.evento} />
            <Container className={Styles.container} fluid>
                {sucesso ?
                    <MsgAprovado />
                    :
                    <MsgNaoAprovado />
                }
                <AvaliacaoAtendimento className="my-5" atendimentoToken={props.atendimentoToken}/>
                <Button onClick={continuarClick} variant="primary" size="lg">Continuar</Button>

            </Container>
        </>
    )

}


PosAtendimento.propTypes = {
    evento: PropTypes.object.isRequired,
    atendimentoToken: PropTypes.string.isRequired
}

export default PosAtendimento;