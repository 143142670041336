import React, { useEffect, useState } from 'react';
import ParticipanteForm from '@panagora/participante-form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import urna from '../../api/urna';


function FormVotante(props) {

    const [loading, setLoading] = useState(false);

    const [urnas, setUrnas] = useState([]);

    useEffect(() => {
        if (!props.evento.id) return;

        urna.fetchList(props.evento.id).then(resposta => {
            if (resposta.isSuccess()) {
                setUrnas(resposta.getData());
            } else {
                alert('Erro ao buscar urnas. Tente novamente mais tarde.');
                console.error(resposta);
            }
        });
    }, [props.evento.id]);


    function onLoadingChange(loading) {
        setLoading(loading);
    }

    async function postVotanteRascunho(votanteRascunho) {
        return props.atendimentoPost({ votanteRascunho });
    }

    return (
        <Container>
            <Alert variant="info">Seu documento não foi encontrado, por favor digite seus dados para ser atendido</Alert>
            <ParticipanteForm
                evento={props.evento}
                submitHandler={postVotanteRascunho}
                onSubmited={props.onParticipanteInserted}
                onLoadingChange={onLoadingChange}
                urnas={urnas}
            />
            <div className="text-right">
                <Button type="submit" disabled={loading} form="formParticipante" variant="primary">Continuar</Button>
            </div>
        </Container>
    );
}


FormVotante.propTypes = {
    evento: PropTypes.object.isRequired,
    onParticipanteInserted: PropTypes.func,
    atendimentoPost: PropTypes.func
};

export default FormVotante;