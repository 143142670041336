import React from 'react';
import PropTypes from 'prop-types';

function TempoEstimado(props) {

    if (props.segundosEstimados === null) {
        return null;
    }

    const min = Math.round(props.segundosEstimados/60);
    return (
        <div className={props.className}>
            <small>Tempo previsto de atendimento: <b>{min} minutos</b></small>
        </div>
    );

}

TempoEstimado.propTypes = {
    segundosEstimados: PropTypes.number,
    className: PropTypes.string
}
export default TempoEstimado;