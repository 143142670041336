import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import PropTypes from 'prop-types';


class MatriculaFuncional extends React.Component {

    static propTypes = {
        value: PropTypes.any.isRequired,
        onChange: PropTypes.func.isRequired,
        titulo: PropTypes.string.isRequired
    };



    handleChange = (event) => {
        const matricula = event.target.value;
        this.props.onChange({ data:{matriculaFuncional: matricula},valid:true });
    }


    render() {
        return (
            <FormGroup controlId="formMatriculaFuncional">
                <FormLabel>{this.props.titulo}</FormLabel>
                <FormControl autoComplete="off" name="matriculaFuncional" required size="lg" onChange={this.handleChange} value={this.props.value} placeholder={this.props.titulo} />
            </FormGroup>
        );
    }
}

export default MatriculaFuncional;