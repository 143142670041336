
import React from 'react';

function MsgAprovado() {


    return (
        <>
            <h1 className="font-weight-bold">Sua identificação foi realizada com sucesso</h1>
            <p>Você foi corretamente identificado na mesa de credenciamento e agora será direcionado para a página de votação.</p>
        </>
    )

}


export default MsgAprovado;