import ResponseModel from "./response-model";



async function post({votanteUuid,votanteRascunho,eventoId}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/eventos/' + eventoId + 
        '/atendimentos');

  const fet = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },    
    body: JSON.stringify({ votanteUuid,votanteRascunho })
  });
  
  switch (fet.status) {
    case 201:
      return { success: true, status: fet.status, data: await fet.json() };
    case 400:
    case 422:
      return { success: false, status: fet.status, data: await fet.json() };
    case 404:
      return { success: false, status: fet.status, data: await fet.json() };
    default:
      alert('Ops... tente novamente mais tarde');
      console.error(await fet.text());
      break;
  }
  return null;
}



async function fetchPosicaoAtendimento({atendimentoToken}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/posicao-atendimento/' + atendimentoToken);

  const fet = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
  
  const response = new ResponseModel();    
  return await response.init(fet); 
}

async function fetchLinkVotacao({atendimentoToken}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/link-votacao/' + atendimentoToken);

  const fet = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
  
  const response = new ResponseModel();    
  return await response.init(fet); 
}

async function avaliarPost({atendimentoToken, avaliacao}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO + '/avaliar-atendimento/' + atendimentoToken);

  const fet = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ avaliacao })
  });
  
  const response = new ResponseModel();    
  return await response.init(fet); 
}



async function sinalOnline({atendimentoToken}) {

  const url = new URL(process.env.REACT_APP_URL_API_CREDENCIAMENTO +
    '/atendimentos/'+atendimentoToken+'/online');


  const fet = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });


  const response = new ResponseModel();    
  return await response.init(fet); 
}



const atendimentoMesa = { 
  post,
  fetchPosicaoAtendimento, 
  fetchLinkVotacao, 
  avaliarPost,
  sinalOnline
};

export default atendimentoMesa;